import React, { Component, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'formik'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import SearchIcon from '@material-ui/icons/Search'
import { Box, Grid, Divider, IconButton } from '@material-ui/core'
import { convertToTimeZone } from 'date-fns-timezone'

import FormikObserver from 'src/components/atoms/FormikObserver'
import TextInput from 'src/components/atoms/TextInput'
import Select from 'src/components/atoms/Select'
import {
  DATE_FORMAT,
  missingValueText,
  movementTypeOptions,
  PILOTAGE_FORM_ACTION,
  PILOTAGE_MODE,
  PILOTAGE_STATUS,
  sideToOptions,
  TIME_FORMAT,
  TRANSFER_METHOD,
} from 'src/utils/constants'
import {
  acceptDecimalOnly,
  acceptIntegerOnly,
  acceptTimeOnly,
  getTimeInputOnBlur,
  getTimeInputOnChange,
} from 'src/utils/events'
import { selectVessel } from 'src/store/vesselLookup/actions'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import { timezoneSelector } from 'src/store/ports/selectors'
import { unsavedPilotageSelector } from 'src/store/ui/selectors'
import { isPilotOnlyChanged } from 'src/utils/isPilotOnlyChanged'
import {
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
} from 'src/store/pilotage/selectors'
import Tooltip from 'src/components/atoms/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'

const StartPlanningBtn = styled(SecondaryButton)`
  && {
    margin-right: auto;
  }
`

const SaveBtn = styled(PrimaryButton)`
  && {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const SearchButton = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.primary.main};
    padding: 9px;
    margin: 28px 16px 0 -9px;

    &:disabled {
      color: unset;
      background-color: rgba(255, 255, 255, 0.1);
    }

    & svg {
      transform: scale(0.85);
    }
  }
`

class CustomDatePickerInput extends Component {
  render () {
    return (
      <div className="react-datepicker-ignore-onclickoutside">
        <div style={{ position: 'relative' }}>
          <TextInput
            label="Date"
            InputProps={{
              autoComplete: 'off',
              placeholder: DATE_FORMAT,
              readOnly: true,
            }}
            {...this.props}
            id="pilotage-date" // place at end - datepicker has it's own id prop we don't care about
          />
          <div />
        </div>
      </div>
    )
  }
}

CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}

const PilotageDetailsForm = ({
  formik,
  isAssignee,
  isReviewMode,
  pilotageLoading,
  onFormChange,
  status,
  fromOptions,
  toOptions,
  routeOptions,
  routeOptionsLoading,
  berthsStationsLoading,
  updateRouteFields,
  pilotOptions,
  attemptSubmit,
  loadRouteOptions,
  selectedVessel,
  onVesselSearch,
  pilotage,
}) => {
  const timeZone = useSelector(timezoneSelector)
  const hasUnsavedChanges = useSelector(unsavedPilotageSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const isOnline = useOnlineStatus()
  const dispatch = useDispatch()

  // Treat it as new if there is no status or it's a draft
  const isDraft = status === PILOTAGE_STATUS.DRAFT
  const isNew = !status || isDraft

  // The fields are editable if this is a new pilotage or the current user is
  // assigned, and the pilotage is not in done or archived status.
  const areFieldsEditable =
    !status ||
    (isAssignee &&
      !isPilotageDone &&
      !isPilotageArchived &&
      !isPilotageCancelled)

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    isSubmitting,
    submitForm,
  } = formik

  useEffect(
    () => {
      if (selectedVessel) {
        setFieldValue('vesselIMO', selectedVessel.IMO)
        setFieldValue('vesselName', selectedVessel.name)
      }
      return () => {
        dispatch(selectVessel(null))
      }
    },
    [selectedVessel]
  )

  const todayAtPort = convertToTimeZone(new Date(), { timeZone })

  const onTimeChange = useMemo(
    () => getTimeInputOnChange(val => setFieldValue('time', val)),
    [setFieldValue]
  )
  const onTimeBlur = useMemo(
    () => getTimeInputOnBlur(val => setFieldValue('time', val)),
    [setFieldValue]
  )

  const pilotSecondOptions = pilotOptions.map(opt =>
    opt.value === missingValueText ? { ...opt, label: missingValueText } : opt
  )

  const onPilotChange = useCallback(
    value => {
      handleChange('pilot')(value)
      if (value === values.pilotSecond) {
        setFieldValue('pilotSecond', missingValueText)
      }
    },
    [handleChange, setFieldValue, values.pilotSecond]
  )

  const onPilotSecondChange = useCallback(
    value => {
      handleChange('pilotSecond')(value)
      if (value === values.pilot) {
        setFieldValue('pilot', missingValueText)
      }
    },
    [handleChange, setFieldValue, values.pilot]
  )

  const pilotageModeOptions = Object
    .values(PILOTAGE_MODE)
    .map(value => ({
      label: value, value
    }))

  const transferMethodOptions = Object
    .values(TRANSFER_METHOD)
    .map(value => ({
      label: value, value
    }))

  // User cannot save if the form is submitting, the pilotage is loading or its status is done.
  let canUserSave = !(isSubmitting || pilotageLoading)

  if (canUserSave) {
    if (isNew || isDraft || isReviewMode) {
      // For new or draft pilotages (or those being reviewed), the only requirement for being
      // able to save is that the current user is assigned.
      canUserSave = isAssignee
    } else {
      // For other existing pilotages, the user can only save if there are unsaved changes and
      // they are either assigned to the pilotage or only changed the assignee fields.
      canUserSave =
        !isPilotageDone &&
        hasUnsavedChanges &&
        (isAssignee || isPilotOnlyChanged(values, pilotage, timeZone))
    }
  }

  const saveBtnLabel =
    isNew || isDraft
      ? 'Save'
      : isReviewMode && !hasUnsavedChanges
        ? 'Confirm'
        : 'Update'

  // From location select field formatter. Handles converting the uuid value to a name.
  const formatFromField = useCallback(
    fromUuid => {
      if (!fromUuid || !fromOptions) {
        return ''
      }
      const from = fromOptions.find(opt => opt.value === fromUuid)
      return from ? from.label : ''
    },
    [fromOptions]
  )

  // To location select field formatter. Handles converting the uuid value to a name.
  const formatToField = useCallback(
    toUuid => {
      if (!toUuid || !toOptions) {
        return ''
      }
      const to = toOptions.find(opt => opt.value === toUuid)
      return to ? to.label : ''
    },
    [toOptions]
  )

  // Route select field formatter. Handles converting the uuid value to a name.
  const formatRouteField = useCallback(
    routeUuid => {
      if (!routeUuid || !routeOptions) {
        return ''
      }
      const route = routeOptions.find(opt => opt.value === routeUuid)
      return route ? route.label : ''
    },
    [routeOptions]
  )

  return (
    <Form>
      <FormikObserver values={values} onChange={onFormChange} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Field
            name="time"
            render={({ field }) => {
              const props = {
                label: 'Time',
                id: 'pilotage-time',
                formikField: {
                  ...field,
                  onChange: onTimeChange,
                  onBlur: onTimeBlur,
                },
                InputProps: {
                  placeholder: TIME_FORMAT,
                  autoComplete: 'off',
                  onKeyDown: acceptTimeOnly,
                },
                inputProps: {
                  maxLength: 5,
                  tabIndex: 1,
                  inputMode: 'numeric',
                  type: 'text',
                  pattern: '[0-9:]*',
                },
                error: touched.time && errors.time,
                disabled: pilotageLoading,
                readOnly: !areFieldsEditable,
              }
              return <TextInput {...props} />
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            selected={values.date}
            value={values.date}
            highlightDates={[todayAtPort]}
            dateFormat="d MMM yyyy"
            onChange={date => setFieldValue('date', date)}
            disabled={pilotageLoading}
            customInput={
              <CustomDatePickerInput error={touched.date && errors.date} />
            }
            readOnly={!areFieldsEditable}
            tabIndex='2'
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="pilotage-mvt"
            label="MVT"
            value={values.movementType}
            onClose={() => setFieldTouched('movementType')}
            onChange={val => {
              setFieldValue('movementType', val)
              updateRouteFields(val, values.from, values.to, setFieldValue)
            }}
            options={movementTypeOptions}
            error={touched.movementType && errors.movementType}
            readOnly={!isNew || !areFieldsEditable || !isOnline}
            disabled={pilotageLoading}
            tabIndex="3"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="pilotage-from"
            label="From"
            value={values.from}
            formatValue={formatFromField}
            onClose={() => setFieldTouched('from')}
            onChange={val => {
              setFieldValue('from', val)
              if (val && values.to) {
                loadRouteOptions(val, values.to)
              }
            }}
            options={fromOptions}
            error={touched.from && errors.from}
            readOnly={!areFieldsEditable || !isOnline}
            disabled={
              pilotageLoading || berthsStationsLoading || !values.movementType
            }
            tabIndex="4"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="pilotage-to"
            label="To"
            value={values.to}
            formatValue={formatToField}
            onClose={() => setFieldTouched('to')}
            onChange={val => {
              setFieldValue('to', val)
              if (val && values.from) {
                loadRouteOptions(values.from, val)
              }
            }}
            options={toOptions}
            error={touched.to && errors.to}
            readOnly={!areFieldsEditable || !isOnline}
            disabled={
              pilotageLoading || berthsStationsLoading || !values.movementType
            }
            tabIndex="5"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="pilotage-side-to"
            label="Side To"
            value={values.sideTo}
            onClose={() => setFieldTouched('sideTo')}
            onChange={handleChange('sideTo')}
            options={sideToOptions}
            error={touched.sideTo && errors.sideTo}
            disabled={pilotageLoading}
            readOnly={!areFieldsEditable || !isOnline}
            tabIndex="6"
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-start">
            {isNew && (
              <SearchButton
                disabled={!areFieldsEditable || pilotageLoading}
                onClick={onVesselSearch}
              >
                <SearchIcon />
              </SearchButton>
            )}
            <Field
              name="vesselIMO"
              render={({ field }) => (
                <TextInput
                  label="Vessel IMO"
                  id="pilotage-vessel-imo"
                  placeholder="Enter IMO #"
                  readOnly={!isNew || !areFieldsEditable || !isOnline}
                  formikField={field}
                  error={touched.vesselIMO && errors.vesselIMO}
                  disabled={pilotageLoading}
                  inputProps={{
                    maxLength: 7,
                    autoComplete: 'off',
                    inputMode: 'numeric',
                    tabIndex: 7,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    onKeyDown: e => {
                      acceptIntegerOnly(e)
                      if (selectedVessel) {
                        setFieldValue('vesselName', '')
                        dispatch(selectVessel(null))
                      }
                    },
                  }}
                  type="number"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="vesselName"
            render={({ field }) => (
              <TextInput
                label="Vessel Name"
                id="pilotage-vessel-name"
                placeholder="From IMO #"
                readOnly={!areFieldsEditable || !isOnline}
                formikField={field}
                error={touched.vesselName && errors.vesselName}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{ autoComplete: 'off' }}
                disabled={pilotageLoading}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  tabIndex: 8,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name="vesselFwdDraft"
            render={({ field }) => (
              <TextInput
                label="Fwd. Draft"
                id="pilotage-fwd-draft"
                unit="m"
                useMissingPlaceholder
                formikField={{
                  ...field,
                  onBlur: evt => {
                    // Format the input of blur (prevents an infinite render loop in onFormChange)
                    const val = evt.target.value
                    const valStr = val && !isNaN(Number(val)) ? Number(val).toFixed(2) : ''
                    setFieldValue('vesselFwdDraft', valStr)
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  autoComplete: 'off',
                  onKeyDown: acceptDecimalOnly,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  inputMode: 'decimal',
                  tabIndex: 9,
                }}
                error={touched.vesselFwdDraft && errors.vesselFwdDraft}
                disabled={pilotageLoading}
                readOnly={!areFieldsEditable}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name="vesselAftDraft"
            render={({ field }) => (
              <TextInput
                label="Aft Draft"
                id="pilotage-aft-draft"
                unit="m"
                useMissingPlaceholder
                formikField={{
                  ...field,
                  onBlur: evt => {
                    // Format the input of blur (prevents an infinite render loop in onFormChange)
                    const val = evt.target.value
                    const valStr = val && !isNaN(Number(val)) ? Number(val).toFixed(2) : ''
                    setFieldValue('vesselAftDraft', valStr)
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  autoComplete: 'off',
                  onKeyDown: acceptDecimalOnly,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  inputMode: 'decimal',
                  tabIndex: 10,
                }}
                error={touched.vesselAftDraft && errors.vesselAftDraft}
                disabled={pilotageLoading}
                readOnly={!areFieldsEditable}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name="vesselDisplacement"
            render={({ field }) => (
              <TextInput
                label="Displacement"
                id="pilotage-vessel-displacement"
                unit="t"
                useMissingPlaceholder
                formikField={{
                  ...field,
                  onBlur: evt => {
                    // Format the input of blur (prevents an infinite render loop in onFormChange)
                    const val = evt.target.value
                    const valStr = val && !isNaN(Number(val)) ? Number(val).toFixed(2) : ''
                    setFieldValue('vesselDisplacement', valStr)
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  autoComplete: 'off',
                  onKeyDown: acceptDecimalOnly,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  inputMode: 'decimal',
                  tabIndex: 11,
                }}
                error={touched.vesselDisplacement && errors.vesselDisplacement}
                disabled={pilotageLoading}
                readOnly={!areFieldsEditable}
              />
            )}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            id="pilotage-route"
            label="Route"
            value={values.route}
            formatValue={formatRouteField}
            onClose={() => setFieldTouched('route')}
            onChange={handleChange('route')}
            options={routeOptions}
            placeholder={
              routeOptions &&
              routeOptions.length === 0 &&
              values.from &&
              values.to
                ? 'No routes available'
                : 'Select'
            }
            error={touched.route && errors.route}
            readOnly={
              !areFieldsEditable ||
              !isOnline ||
              (routeOptions && routeOptions.length === 0)
            }
            disabled={
              pilotageLoading ||
              !values.from ||
              !values.to ||
              (routeOptionsLoading && !routeOptions)
            }
            tabIndex='12'
          />
          <Box mt={2}>
            <Select
              id="pilotage-pilotage-mode"
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <span style={{paddingRight: 10}}>Pilotage Mode</span>
                  <Tooltip content={
                    values.pilotageMode === PILOTAGE_MODE.DIRECT
                      ? TOOLTIPS.PILOTAGE_MODE_DIRECT
                      : values.pilotageMode === PILOTAGE_MODE.INDIRECT
                        ? TOOLTIPS.PILOTAGE_MODE_INDIRECT
                        : ''
                  } />
                </Box>
              }
              value={values.pilotageMode}
              onClose={() => setFieldTouched('pilotageMode')}
              onChange={value => setFieldValue('pilotageMode', value)}
              options={pilotageModeOptions}
              error={touched.pilotageMode && errors.pilotageMode}
              readOnly={!areFieldsEditable}
              disabled={pilotageLoading}
              tabIndex='13'
            />
          </Box>
          <Box mt={2}>
            <Select
              id="pilotage-transfer-method"
              label="Transfer Method"
              value={values.transferMethod}
              onClose={() => setFieldTouched('transferMethod')}
              onChange={value => setFieldValue('transferMethod', value)}
              options={transferMethodOptions}
              error={touched.transferMethod && errors.transferMethod}
              readOnly={!areFieldsEditable}
              disabled={pilotageLoading || !isOnline}
              tabIndex='14'
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Select
            id="pilotage-pilot"
            label="Pilot (Primary)"
            value={values.pilot}
            onClose={() => setFieldTouched('pilot')}
            onChange={onPilotChange}
            options={pilotOptions}
            error={touched.pilot && errors.pilot}
            readOnly={
              !isNew &&
              (isPilotageDone || isPilotageArchived || isPilotageCancelled)
            }
            formatValue={uuid =>
              (pilotOptions.find(item => item.value === uuid) || {}).label ||
              'Unassigned'
            }
            disabled={pilotageLoading || !isOnline}
            tabIndex='15'
          />
          <Box mt={2}>
            <Select
              id="pilotage-pilot-second"
              label="Pilot (Secondary)"
              value={values.pilotSecond}
              onClose={() => setFieldTouched('pilotSecond')}
              onChange={onPilotSecondChange}
              options={pilotSecondOptions}
              error={touched.pilotSecond && errors.pilotSecond}
              readOnly={
                !isNew &&
                (isPilotageDone || isPilotageArchived || isPilotageCancelled)
              }
              formatValue={uuid =>
                (pilotSecondOptions.find(item => item.value === uuid) || {})
                  .label || missingValueText
              }
              disabled={pilotageLoading || !isOnline}
              tabIndex='16'
            />
          </Box>
          <Box mt={2}>
            <Field
              name="vesselAgent"
              render={({ field }) => (
                <TextInput
                  label="Agent"
                  id="pilotage-vessel-agent"
                  readOnly={!areFieldsEditable || !isOnline}
                  formikField={field}
                  error={touched.vesselAgent && errors.vesselAgent}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{ autoComplete: 'off' }}
                  disabled={pilotageLoading}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    tabIndex: 17,
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} display="flex" justifyContent="flex-end">
        {!status && (
          <StartPlanningBtn
            disabled={!canUserSave}
            onClick={() =>
              attemptSubmit(
                submitForm,
                PILOTAGE_FORM_ACTION.SAVE_AND_START_PLANNING
              )
            }
          >
            Start Planning
          </StartPlanningBtn>
        )}
        {!status && (
          <SecondaryButton
            disabled={!canUserSave}
            onClick={() =>
              attemptSubmit(
                submitForm,
                PILOTAGE_FORM_ACTION.SAVE_AND_START_ANOTHER
              )
            }
          >
            Create Another
          </SecondaryButton>
        )}
        <SaveBtn
          disabled={!canUserSave}
          onClick={() =>
            attemptSubmit(
              submitForm,
              isReviewMode
                ? PILOTAGE_FORM_ACTION.SAVE_AND_START_PLANNING
                : PILOTAGE_FORM_ACTION.SAVE_AND_CLOSE
            )
          }
        >
          {saveBtnLabel}
        </SaveBtn>
      </Box>
    </Form>
  )
}

PilotageDetailsForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    isSubmitting: PropTypes.bool,
    changeHandler: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  status: PropTypes.string,
  isReviewMode: PropTypes.bool,
  isAssignee: PropTypes.bool,
  pilotageLoading: PropTypes.bool,
  onFormChange: PropTypes.func,
  fromOptions: PropTypes.array,
  toOptions: PropTypes.array,
  routeOptions: PropTypes.array,
  routeOptionsLoading: PropTypes.bool,
  berthsStationsLoading: PropTypes.bool,
  updateRouteFields: PropTypes.func,
  pilotOptions: PropTypes.array,
  attemptSubmit: PropTypes.func,
  loadRouteOptions: PropTypes.func,
  selectedVessel: PropTypes.object,
  onVesselSearch: PropTypes.func,
  pilotage: PropTypes.object,
}

export default PilotageDetailsForm
