import React, { Component, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Divider, Typography } from '@material-ui/core'
import { convertToTimeZone, formatToTimeZone } from 'date-fns-timezone'
import DatePicker from 'react-datepicker'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { missingValueText, TIME_FORMAT } from 'src/utils/constants'
import {
  acceptTimeOnly,
  getTimeInputOnBlur,
  getTimeInputOnChange,
} from 'src/utils/events'

const RecordButton = styled(PrimaryButton)`
  && {
    width: 150px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    font-size: 0.9em;
  }
`
const ClearButton = styled(PrimaryButton)`
  && {
    font-weight: 400;
    font-size: 11px !important;
    min-width: inherit !important;
    width:60px;
    margin-left:5px;
    margin-right:5px;
    padding: 5px 10px;
    background-color: #3E4E6C;
  }
`

const ItemContainer = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(1.25, 3.75)};
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${theme.palette.background.light};
  margin-bottom: ${theme.spacing(1)}px;
  border-radius: 8px;
`
)

const TimestampLabel = styled.span(
  ({ theme }) => `
  text-transform: uppercase;
  color: ${theme.palette.text.primary};
  font-weight: ${theme.weights.fontWeightMedium};
`
)

const StyledInput = styled.input(
  ({ theme, inputWidth, invalid }) => `
  padding: ${theme.spacing(1)}px;
  margin-left: ${theme.spacing(1)}px;
  outline: none;
  border: none;
  border-bottom: 1px dashed;
  border-bottom-color: ${invalid ? theme.palette.error.main : '#667890'}
  text-align: center;
  background: transparent;
  border-radius: 4px;
  width: ${inputWidth}px;
  color: ${theme.palette.text.primary}

  &:focus {
    background: ${theme.palette.slightlyFaded};
    border-bottom-color: transparent;
  }
`
)

class CustomDatePickerInput extends Component {
  render() {
    return (
      <div style={{ position: 'relative' }}>
        <StyledInput
          type="text"
          {...this.props}
          inputWidth={124}
          readOnly
          placeholder={missingValueText}
        />
        <div />
      </div>
    )
  }
}

CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}

const getFieldValue = (savedValue, editedValue) =>
  editedValue === undefined ? savedValue : editedValue

const formatDate = (dateTime, timeZone) =>
  dateTime ? convertToTimeZone(dateTime, { timeZone }) : ''

const formatTime = (dateTime, timeZone) =>
  dateTime ? formatToTimeZone(dateTime, 'HH:mm', { timeZone }) : ''

const TimestampItem = ({
  timeZone,
  label,
  dateTime,
  editedTime,
  editedDate,
  dateTimeEta,
  editedTimeEta,
  editedDateEta,
  isValidTime,
  isValidTimeEta,
  onRecordNow,
  onClearDateTime,
  onClearDateTimeEta,
  onEditTime,
  onEditDate,
  onEditTimeEta,
  onEditDateEta,
  disabled,
}) => {
  const todayAtPort = convertToTimeZone(new Date(), { timeZone })

  const dateValue = getFieldValue(formatDate(dateTime, timeZone), editedDate)
  const timeValue = getFieldValue(formatTime(dateTime, timeZone), editedTime)
  const dateEtaValue = getFieldValue(formatDate(dateTimeEta, timeZone), editedDateEta)
  const timeEtaValue = getFieldValue(formatTime(dateTimeEta, timeZone), editedTimeEta)

  const onTimeChange = useMemo(() => getTimeInputOnChange(onEditTime), [
    onEditTime,
  ])
  const onTimeEtaChange = useMemo(() => getTimeInputOnChange(onEditTimeEta), [
    onEditTimeEta,
  ])
  const onTimeBlur = useMemo(() => getTimeInputOnBlur(onEditTime), [onEditTime])
  const onTimeEtaBlur = useMemo(() => getTimeInputOnBlur(onEditTimeEta), [onEditTimeEta])

  const canClear = (dateValue || timeValue) && !disabled
  const canClearEta = (dateEtaValue || timeEtaValue) && !disabled

  return (
    <ItemContainer>
      <Box flex="1">
        <TimestampLabel>{label}</TimestampLabel>
      </Box>
      <Box display="flex" alignItems="center">
        <Box alignItems="center" textAlign="center" ml={2} mr={3}>
          <div>
            <Typography variant="body2">Planned / ETA</Typography>
          </div>
          <Box display="flex" alignItems="center">
            <StyledInput
              type="text"
              invalid={!isValidTimeEta || (dateTimeEta && editedTimeEta === '')}
              value={timeEtaValue}
              placeholder={TIME_FORMAT}
              inputWidth={84}
              disabled={disabled}
              onChange={onTimeEtaChange}
              onBlur={onTimeEtaBlur}
              onKeyDown={acceptTimeOnly}
              maxLength={5}
              inputMode={'numeric'}
              pattern={'[0-9]*'}
            />
            <DatePicker
              selected={dateEtaValue}
              value={dateEtaValue}
              highlightDates={[todayAtPort]}
              dateFormat="d MMM yyyy"
              disabled={disabled}
              onChange={date => onEditDateEta(date)}
              customInput={
                <CustomDatePickerInput
                  invalid={!dateTimeEta && editedTimeEta && isValidTimeEta && !editedDateEta}
                />
              }
            />
          </Box>
        </Box>
        <Box>
          <ClearButton onClick={onClearDateTimeEta} disabled={!canClearEta}>
            Clear
          </ClearButton>
        </Box>
        <Divider light orientation="vertical" flexItem style={{ margin: 0 }} />
        <Box alignItems="center" textAlign="center" ml={2} mr={3}>
          <div>
            <Typography variant="body2">Actual Time</Typography>
          </div>
          <Box display="flex" alignItems="center">
            <StyledInput
              type="text"
              invalid={!isValidTime || (dateTime && editedTime === '')}
              value={timeValue}
              placeholder={TIME_FORMAT}
              inputWidth={84}
              disabled={disabled}
              onChange={onTimeChange}
              onBlur={onTimeBlur}
              onKeyDown={acceptTimeOnly}
              maxLength={5}
              inputMode={'numeric'}
              pattern={'[0-9]*'}
            />
            <DatePicker
              selected={dateValue}
              value={dateValue}
              highlightDates={[todayAtPort]}
              dateFormat="d MMM yyyy"
              disabled={disabled}
              onChange={date => onEditDate(date)}
              customInput={
                <CustomDatePickerInput
                  invalid={!dateTime && editedTime && isValidTime && !editedDate}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <RecordButton onClick={onRecordNow} disabled={disabled}>
          {dateTime ? 'Record again' : 'Record'}
        </RecordButton>
        <ClearButton onClick={onClearDateTime} disabled={!canClear}>
          Clear
        </ClearButton>
      </Box>
    </ItemContainer>
  )
}

TimestampItem.propTypes = {
  timeZone: PropTypes.string,
  label: PropTypes.string,
  dateTime: PropTypes.string,
  editedTime: PropTypes.string,
  editedDate: PropTypes.instanceOf(Date),
  dateTimeEta: PropTypes.string,
  editedTimeEta: PropTypes.string,
  editedDateEta: PropTypes.instanceOf(Date),
  isValidTime: PropTypes.bool,
  isValidTimeEta: PropTypes.bool,
  onRecordNow: PropTypes.func,
  onClearDateTime: PropTypes.func,
  onClearDateTimeEta: PropTypes.func,
  onEditTime: PropTypes.func,
  onEditDate: PropTypes.func,
  onEditTimeEta: PropTypes.func,
  onEditDateEta: PropTypes.func,
  disabled: PropTypes.bool,
}

export default React.memo(TimestampItem)
